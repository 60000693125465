import React from 'react';
import './App.css';
import { tns } from "tiny-slider/src/tiny-slider";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kodexChanged: false,
            showNextArrow: false,
            started: false,
            number: {
                first: '',
                second: '',
            },
            ageGuessed: false,
            emojiSuccess: false,
        };
    }

    startKodexTimer() {
        setTimeout(() => {
            this.setState({ kodexChanged: true })
            this.slider.refresh();
            setTimeout(() => {
                this.setState({ showNextArrow: true })
            }, 1000)
        }, 8000)
    }

    numberChange(target) {
        if (target.id === 'first-number') {
            this.setState({ number: { first: target.value, second: this.state.number.second } })
            if (target.value) {
                document.getElementById('second-number').focus()
            }
        }

        if (target.id === 'second-number') {
            this.setState({ number: { first: this.state.number.first, second: target.value } })
            document.getElementById('second-number').focus()
        }

        setTimeout(() => {
            if (this.state.number.first === '2' && this.state.number.second === '0') {
                this.setState({ ageGuessed: true });
                this.slider.refresh()
                setTimeout(() => {
                    this.setState({ showNextArrow: true });
                }, 0);
            }
        })
    }

    componentDidMount() {
        this.slider = tns({
            container: '.mainSlider',
            items: 1,
            slideBy: 'page',
            autoHeight: true,
            autoplay: false,
            nav: false,
            loop: false,
            controlsText: ['<span class="icon-arrow-left"></span>', '<span class="icon-arrow-right"></span>']
        });

        this.slider.events.on('indexChanged', (props) => {
            this.setState({ showNextArrow: false })
            if (props.index === 1) {
                this.startKodexTimer()
            }
        });

        setTimeout(() => {
            this.setState({ showNextArrow: true })
        }, 5000)
    }

    emojiClick(target) {
        if (target.className.includes('correct')) {
            this.setState({ emojiSuccess: true });
            this.slider.refresh();
            setTimeout(() => {
                this.slider.refresh();
            }, 200)
        }
    }

    openFullScreen() {
        var elem = document.documentElement;

        /* View in fullscreen */
        function openFullscreen() {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
        }

        openFullscreen();
        this.setState({ started: true })
    }

    render() {
        return (
            <div className={`Container ${this.state.showNextArrow ? 'can-go' : ''}`}>
                <div className={'no-mobile is-hidden-desktop'}><h1>Sajnos csak laptopon tudod kideríteni mi van itt 🤕</h1></div>
                {!this.state.started && (
                    <div className={'start'}>
                        <h2>Ha készen állsz a szülinapi játékodra kattints a gombra!</h2>
                        <button className='button is-primary' onClick={() => this.openFullScreen()}>Kezdjük!</button>
                    </div>
                )}
                <div className="mainSlider">
                    <div className="Section">
                        <div>
                            <h1>Szia kedves Dóri!</h1>
                            <h3>Mivel személyesen nincsen alkalmam sajnos felköszönteni ezen a neves és csodálatos napon, ezért így weboldalon keresztül szeretnék neked</h3>
                            <h1>Boldog Születésnapot kívánni!</h1>
                            <h4>Ha készen állsz a feladatra akkor kattints a nyilacskára jobb oldalt!</h4>
                        </div>
                    </div>
                    <div className="Section">
                        <div>
                            {!this.state.kodexChanged ? (
                                <>
                                    <h1>1. Kódex fejtés</h1>
                                    <img className='kodex' src="/kódex.jpeg" />
                                    <textarea className='textarea' placeholder="Írd ide a helyes megfejtést" />
                                </>
                            ) : (
                                <>
                                    <h1>Csak vicceltem 😂</h1>
                                    <h2>Mehetsz tovább...</h2>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="Section">
                        <div>
                            {!this.state.ageGuessed ? (
                                <>
                                    <h1>2. Tippelj!</h1>
                                    <h3>Itt meg kell tippelned hogy melyik életév a legkirályabb egy ember életében?</h3>
                                    <div className='numbers'>
                                        <input type='number' id="first-number" onChange={(e) => this.numberChange(e.target)} className='input' />
                                        <input type='number' id="second-number" onChange={(e) => this.numberChange(e.target)} className='input' />
                                    </div>
                                </>
                            ) : <>
                                <h1>Ügyes vagy!!!</h1>
                                <h3>A 20. életév a leges legjobb éved, élvezd ki nagyon!</h3>
                                <br/>
                                <br/>
                                <br/>
                                <h4>Mehet a kövi?</h4>
                            </>}
                        </div>
                    </div>
                    <div className="Section">
                        <div>
                            {!this.state.emojiSuccess ? (
                                <>
                                    <h1>2. Kattints!</h1>
                                    <h3>Nem kapsz segítséget. Kattints a jó emojira.</h3>
                                    <div className='emojis'>
                                        {[1,2,3,4,5,6,7,8,9,10,11].map(num => {
                                            return <img key={num} src={`/emoji/${num}.png`} className={num === 11 ? 'correct' : ''} onClick={(e) => this.emojiClick(e.target)} />
                                        })}
                                    </div>
                                </>
                            ) : <>
                                {/*https://www.wish.com/search/pig/product/5bf63f7551a5d40a225bc223?&source=search&position=20&share=web*/}
                                <h1>Bravó!!!</h1>
                                <h3>Mivel ilyen ügyes voltál, születésnapodra kapsz egy plüss malacot!</h3>
                                <div className="pig">
                                    <img src="/pig.jpeg" />
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
